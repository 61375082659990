import React from "react";
import CountUp from "react-countup";
import LazyLoad from "react-lazyload";

const Stats = () => {
  return (
    <div className="bg-digma-dark text-white pt-13 pt-md-18 pt-lg-10 pb-5 pb-md-9 pb-lg-30" style={{position: "relative", top:"-2px", zIndex:"100"}}>
      <div className="container text-white">
        <h4 className=" font-size-10" data-aos="fade-right"
                  data-aos-duration={900}>Why now?</h4>
        <h5 className="mt-10 text-white font-size-9" data-aos="fade-right"
                  data-aos-duration={900}>Video marketing is bigger than ever!</h5>
        <p className="heading-default-color font-size-6 mb-4 mb-lg-9 text-white" data-aos="fade-right"
                  data-aos-duration={900}>Video is everywhere. Social media, the rise of ‘stories’, and the way in which people consume media have all contributed to making video more important than ever before.</p>
        <h6 className="mt-10 text-white font-size-9" data-aos="fade-right"
                  data-aos-duration={900}>Current climate​</h6>
        <p className="heading-default-color font-size-6 mb-4 mb-lg-9 text-white" data-aos="fade-right"
                  data-aos-duration={900}>Times are tough and companies are looking for new and engaging ways to attract clients without breaking the bank.</p>
        <div className="mt-10 row text-white">
          {/* Single Counter */}
          <div className="col-lg-4 col-md-3 col-xs-6">
            <div
              className="mb-11 mb-lg-0"
              data-aos="fade-up"
              data-aos-duration={600}
            >
              <span className="font-size-11 mb-5 ">
                <LazyLoad>
                  <span className="counter ">
                    <CountUp duration={3} end={100} />
                  </span>
                  &nbsp;mins
                </LazyLoad>
              </span>
              <p className="heading-default-color mb-0 font-size-5 text-white">
                The average amount of time a person will spend watching video in 2021 per day!
              </p>
            </div>
          </div>
          {/* End Single Counter */}
          {/* Single Counter */}
          <div className="col-lg-4 col-md-3 col-xs-6">
            <div
              className="mb-11 mb-lg-0"
              data-aos="fade-up"
              data-aos-duration={900}
            >
              <span className="font-size-11 mb-5 ">
                <LazyLoad>
                  <span className="counter ">
                    <CountUp duration={3} end={43} />
                  </span>
                  %
                </LazyLoad>
              </span>
              <p className="heading-default-color mb-0 font-size-5 text-white">
              The percentage of consumers who want interactivity, with the ability to decide what information they want to view and when they want to view it.​
              </p>
            </div>
          </div>
          {/* End Single Counter */}
          {/* Single Counter */}
          <div className="col-lg-4 col-md-3 col-xs-6">
            <div
              className="mb-11 mb-lg-0"
              data-aos="fade-up"
              data-aos-duration={1200}
            >
              <span className="font-size-11 mb-5 ">
                <LazyLoad>
                  <span className="counter ">
                    <CountUp duration={3} end={92} />
                  </span>
                  %
                </LazyLoad>
              </span>
              <p className="heading-default-color mb-0 font-size-5 text-white">
              The percentage of marketers who say video content is an important part of their marketing strategy.​
              </p>
            </div>
          </div>
          {/* End Single Counter */}
        </div>
      </div>    
    </div>
  );
};

export default Stats;
