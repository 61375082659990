import React from "react";
import {Link} from "gatsby";
import PageWrapper from "../../components/PageWrapper";
import Hero from "../../sections/stuartverschoyle/Hero";
import Content from "../../sections/stuartverschoyle/Content";
import Stats from "../../sections/stuartverschoyle/Stats";
import CTA from "../../sections/CTA/CTA";
import Wave from "../../sections/common/Wave";
import SEO from '../../components/SEO';
import imgC from "../../assets/image/inner-page/jpg/stuart-verschoyle.jpeg";

const PricingPage = () => {

  // Example of dynamically using location prop as a crumbLabel
  // NOTE: this code will not work for every use case, and is only an example
  return (
    <>
      <PageWrapper        
        themeConfig={{
          headerClassName: "site-header--menu-left",
          headerFluid: false,

          footerStyle: "digma",
        }}>
        <SEO
          title="Meet the CEO"
          description="Stuart has vast experience in front-end web development and interactive video which lead him to the creation of Digma."
          image={imgC}
        />
        <div className="container pt-20 pt-md-16 pt-lg-20 ">
          <nav className="breadcrumb" aria-label="Breadcrumb">
            <ol className="breadcrumb__list">
            <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/">Home</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li>
              {/* <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/meet-the-team">Meet The Team</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li>               */}
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/meet-the-team/stuart-verschyle-ceo">Stuart Verschoyle</Link>
              </li>
              </ol>
           </nav>
        </div>
        <Hero />
        <Content />
        <Wave color="#0c426d" />         
        <Stats />
        <CTA className="bg-images" />
      </PageWrapper>
    </>
  );
};
export default PricingPage;
