import React from "react";

import imgb from "../../assets/image/inner-page/jpg/stuart-verschoyle.jpeg";
import imgDP from "../../assets/image/home-2/png/dot-pattern-black.png";

const Content = () => {
  return (
    <div className="pb-11 pb-md-15 pb-lg-27">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          {/* Right */}
          <div className="order-1 col-xl-5 col-lg-5 col-md-5 col-xs-10">
            <div className="about-content-image-group-1 mb-8 mb-lg-0">
              <div className="img-1">
                <img
                  className="w-100 rounded-10"
                  src={imgb}
                  alt="Digma Mobile interactive video"
                  data-aos="fade-right"
                  data-aos-duration={600}
                />
                <div className="img-2 z-index-n1 mt-n13">
                  <img
                    className="w-100"
                    src={imgDP}
                    alt="Spotted pattern"
                    data-aos="fade-right"
                    data-aos-duration={900}
                    data-aos-delay={300}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* Right */}
          {/* Left */}
          <div className="order-2 col-xl-6 col-lg-7 col-md-7 col-xs-10">
            <div
              className="mx-lg-14 mx-xl-14"
              data-aos="fade-left"
              data-aos-duration={600}
            >
              <p className="heading-default-color font-size-6 mb-4 mb-lg-9">
              After gaining experience for almost two decades from the best digital agencies, I felt it was the right time to work for myself and start a company doing what I am most passionate about. And those are interactive videos. I wanted to create a business which will be at the forefront of this particular technology and help SMEs with making the most of their video content. Founded over the pandemic, I knew that small companies may want to recycle their videos and thought what better way than to make it interactive and freshen it up.
              </p>
            </div>
          </div>
          {/* Left End */}
          {/* Left */}
          <div className="order-3 col-xl-10 col-lg-10 col-md-10 col-xs-10">
            <div
              className="mt-10 mx-lg-14 mx-xl-14"
              data-aos="fade-left"
              data-aos-duration={600}
            >
             <h3 className="mb-4 mt-10 mb-lg-9"> My Childhood Dream to Make a Difference</h3>
              <p className="heading-default-color font-size-6 mb-4 mb-lg-9">Born and raised in Guildford, I found myself interested in IT at around 11 years old at school many moons ago. Even more so when I was first introduced to the "world wide web" by my godfather who is paralysed from the neck down from an unfortunate trampolining accident. He had been given special software and hardware so he could use the internet and communicate with the outside world even though he was stuck at home. Because of this, I moved to Bournemouth to study Multimedia Communications at a university and found my interest in website development and human computer interaction. Unsurprisingly, my dissertation was based around my godfather on creating user-friendly computer software for those with disabilities.</p>
              <p className="heading-default-color font-size-6 mb-4 mb-lg-9">Being able to create something unique that the whole world could see and make use of is an amazing concept to me; and I’ve found just the right technology for this. </p>
              <h3 className="mb-4 mt-10 mb-lg-9">How Was Digma Born</h3>
              <p className="heading-default-color font-size-6 mb-4 mb-lg-9">Like for most of us, the pandemic period has been bumpy as I was furloughed and made redundant. Interestingly, my girlfriend had also just become pregnant at the time and I had a new mortgage to pay. I carefully considered all the risks and decided it’s now or never - and that's how I started to slowly build Digma.</p>
              <p className="heading-default-color font-size-6 mb-4 mb-lg-9">Of course, it wasn’t without a little help from my long-term friend Chris from primary school that I’ve known for 34 years. As he came back from Singapore, we joined forces and have been building this interactive player together ever since. Chris has been working on the CMS whilst I have been coming up with the UX and have given a good go at sales and business. </p>
              <h3 className="mb-4 mt-10 mb-lg-9">The Digma Platform</h3>
              <p className="heading-default-color font-size-6 mb-4 mb-lg-9">Having the ability to instantly retrieve information at the touch of a button - or at the the tip of your fingers - has become part of everyday modern life.</p>
              <p className="heading-default-color font-size-6 mb-4 mb-lg-9">Yet amidst this progress one neglected area that has yet to be modified is today's mobile video technology.</p>
              <p className="heading-default-color font-size-6 mb-4 mb-lg-9">Our prerogative was to develop a new, exciting and practical way to retrieve important and timely information within video content. To drastically move on from the clunky and frustrating process of scrubbing backwards and forwards on a timeline. A process that seems so archaic in today’s accelerated technological climate.</p>
              <p className="heading-default-color font-size-6 mb-4 mb-lg-9">Committed to the concept of retrieving timely content on demand, our aim was to take video technology into the next era.</p>
              <p className="heading-default-color font-size-6 mb-4 mb-lg-9">The result is DIGMA, a newly developed video player suited for every type of industry. From cook-a-long videos and drone fly through tours, to marketing and real estate.</p>
              <p className="heading-default-color font-size-6 mb-4 mb-lg-9">The platform has gone through many iterations and we came to a conclusion that it’s best to cater for all types of industries at all levels. We have price plans which suit small campaigns, small companies and agencies, with the ability to white-label our product. </p>
              <p className="heading-default-color font-size-6 mb-4 mb-lg-9">We offer templates and plugins which can easily be implemented with very little knowledge of design and look professional at the highest standard. Now we have launched our CMS and would love to have you on board with us on this journey.</p>              
            </div>
          </div>

        
        </div>
      </div>
    </div>
  );
};

export default Content;
